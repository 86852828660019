import React from 'react';
import PropTypes from 'prop-types';

class BaseSearchBox extends React.Component {
  // max-age(Cookieの秒単位の有効期限)は90日を秒数で指定
  static MAX_AGE_OF_LOCATION = '7776000';

  constructor (props) {
    super(props);
    this.state = {
      isActive: false,
      isDiseaseModalActive: false,
      isAreaModalActive: false,
      isCurretLocationLoading: false,
      isSearchLoading: false,
      selectedType: this.convertInstitutionType(this.props.institutionType), // 'hospital', 'clinic', 'dental
      selectedDisease: {
        name: this.props.diseaseName || '',
        code: this.props.diseaseCode || ''
      },
      selectedPrefecture: {
        name: this.props.prefectureName || this.getCookie('prefectureName'),
        code: this.props.prefectureCode || this.getCookie('prefectureCode')
      },
      selectedCity: this.initialSelectedCity(),
      selectedStation: {
        name: this.props.stationName || '',
        code: this.props.stationCode || '',
        lineName: this.props.stationLineName || ''
      },
      selectedDepartment: {
        name: this.props.departmentName || '',
        code: this.props.departmentCode || ''
      },
      selectedArea: '',
      keyword: this.props.keyword
    };
    this.tooltipOpen = this.tooltipOpen.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.tooltipRef = React.createRef();
    this.scrollTop = this.scrollTop.bind(this);
    this.openAreaModal = this.openAreaModal.bind(this);
    this.openDiseaseModal = this.openDiseaseModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  componentDidMount () {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside);
  }

  initialSelectedCity = () => {
    const { cityName, cityCode, prefectureName } = this.props;

    if (cityCode) {
      return { name: cityName, code: cityCode };
    }

    // 都道府県のみ選択されている場合は、Cookieに保存されている市区町村情報は設定しない
    if (prefectureName) {
      return { name: '', code: '' };
    }

    return {
      name: this.getCookie('cityName'),
      code: this.getCookie('cityCode')
    };
  }

  getCookie = (key) => {
    const cookies = document.cookie;
    const cookiesArray = cookies.split(';');
    const targetCookie = cookiesArray.find((item) => {
      return item.trim().split('=')[0] === key;
    });
    const val = targetCookie?.split('=')[1];

    return val ? decodeURIComponent(val) : '';
  }

  tooltipOpen (event) {
    event.stopPropagation(); // クリックイベントがバブルアップするのを防ぐ
    this.setState({ isActive: !this.state.isActive });
  }

  handleClickOutside (event) {
    if (this.tooltipRef.current && !this.tooltipRef.current.contains(event.target)) {
      this.setState({ isActive: false });
    }
  }

  handleCheckType = (event) => {
    this.setState({ selectedType: event.target.value });
  }

  scrollTop () {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop);
  }

  closeModal (event) {
    this.setState({ isDiseaseModalActive: false, isAreaModalActive: false });
    if (event) event.preventDefault();
    const body = document.getElementsByTagName('body')[0];
    const scrollY = body.style.top;
    body.classList.remove('fixed');
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    body.style.top = '';
  }

  openAreaModal () {
    const scrollPosition = this.scrollTop();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('fixed');
    body.style.top = `${scrollPosition * -1}px`;
    this.setState({ isAreaModalActive: true });
  }

  openDiseaseModal () {
    const scrollPosition = this.scrollTop();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('fixed');
    body.style.top = `${scrollPosition * -1}px`;
    this.setState({ isDiseaseModalActive: true });
  }

  handleSelectDisease = (disease) => {
    this.closeModal();
    this.setState({ selectedDisease: disease, selectedDepartment: { name: '', code: '' } });
  }

  handleSelectDepartment = (department) => {
    this.closeModal();
    this.setState({ selectedDisease: { name: '', code: '' }, selectedDepartment: department });
  }

  handleSelectPrefecture = (prefecture) => {
    this.setState({ selectedPrefecture: prefecture });
  }

  handleEntirePrefecture = () => {
    this.closeModal();
    this.setState({ selectedCity: {} });
  }

  handleSelectCity = (city) => {
    this.closeModal();
    this.setState({ selectedCity: city, selectedStation: {} });
  }

  handleSelectStation = (station) => {
    this.closeModal();
    this.setState({ selectedStation: station, selectedCity: {} });
  }

  handleSelectEntirePrefecture = () => {
    this.closeModal();
    this.setState({ selectedCity: {}, selectedStation: {} });
  }

  handleKeywordInput = (event) => {
    this.setState({ keyword: event.target.value });
  }

  changeCurrentLocationLoading = (isCurretLocationLoading) => {
    this.setState({ isCurretLocationLoading: isCurretLocationLoading });
  }

  setCurrentLocation = (prefecture, city) => {
    this.closeModal();
    this.setState({ selectedPrefecture: prefecture, selectedCity: city, isCurretLocationLoading: false });
  }

  selectedArea = () => {
    let area = '';

    if (this.state.selectedPrefecture?.name) {
      area += this.state.selectedPrefecture.name;

      if (this.state.selectedCity?.name && !this.state.selectedStation?.name) {
        area += this.state.selectedCity.name;
      } else if (this.state.selectedStation?.name) {
        area = `${this.state.selectedStation.name}（${this.state.selectedStation.lineName}）`;
      } else {
        area += '全域';
      }
    } else if (this.state.selectedStation?.name) {
      area = `${this.state.selectedStation.name}（${this.state.selectedStation.lineName}）`;
    }

    return area;
  }

  clearSelectedDiseaseAndDepartment = () => {
    this.setState({ selectedDisease: { name: '', code: '' }, selectedDepartment: { name: '', code: '' } });
  }

  clearSelectedArea = () => {
    this.setState({ selectedStation: { name: '' }, selectedCity: { name: '' }, selectedPrefecture: { name: '' } });
  }

  onClickSearch = (event) => {
    event.preventDefault();
    this.setState({ isSearchLoading: true });
    this.setCookiesOfArea();
    location.href = this.generateSearchUrl();
  }

  setCookiesOfArea = () => {
    const { selectedPrefecture, selectedCity, selectedStation } = this.state;

    // 都道府県が選択されていない、もしくは駅が選択されている場合、エリアでの検索がされていないため、Cookieの更新を行わない
    if (!selectedPrefecture?.code || selectedStation?.code) {
      return;
    }

    document.cookie = `prefectureCode=${selectedPrefecture.code}; max-age=${BaseSearchBox.MAX_AGE_OF_LOCATION}; path=/; Secure`;
    document.cookie = `prefectureName=${selectedPrefecture.name}; max-age=${BaseSearchBox.MAX_AGE_OF_LOCATION}; path=/; Secure`;

    if (selectedCity?.code) {
      document.cookie = `cityCode=${selectedCity.code}; max-age=${BaseSearchBox.MAX_AGE_OF_LOCATION}; path=/; Secure`;
      document.cookie = `cityName=${selectedCity.name}; max-age=${BaseSearchBox.MAX_AGE_OF_LOCATION}; path=/; Secure`;
    } else {
      document.cookie = 'cityCode=; max-age=0; path=/; Secure';
      document.cookie = 'cityName=; max-age=0; path=/; Secure';
    }
  }

  extractPrefecturePaths = () => {
    return this.props.prefectureData.reduce((acc, prefecture) => {
      prefecture.items.forEach(item => {
        acc[item.code] = item.path;
      });
      return acc;
    }, {});
  };

  convertInstitutionType = (institutionType) => {
    switch (institutionType) {
    case 'h':
      return 'hospital';
    case 'c':
      return 'clinic';
    case 'd':
      return 'dental';
    default:
      return 'hospital';
    }
  };

  generateSearchUrl = () => {
    const paths = ['hospitals'];
    const { selectedType, selectedDisease, selectedDepartment, selectedPrefecture, selectedCity, selectedStation, keyword } = this.state;

    // 種別の追加
    switch (selectedType) {
    case 'hospital':
      paths.push('h');
      break;
    case 'clinic':
      paths.push('c');
      break;
    case 'dental':
      paths.push('d');
      break;
    default:
      break;
    }

    // 都道府県が選択されている場合
    if (selectedPrefecture.code) {
      const { path, code } = selectedPrefecture;
      const prefecturePathNames = this.extractPrefecturePaths();
      path ? paths.push(path) : paths.push(prefecturePathNames[code]);
    }

    // 市区町村または駅が選択されている場合
    if (selectedCity.code) {
      paths.push(`CTY${selectedCity.code}`);
    } else if (selectedStation.code) {
      paths.push(`STN${selectedStation.code}`);
    }

    // 疾患または診療科が選択されている、もしくはすでにキャンペーンコードで検索が実施されている場合
    if (selectedDisease.code) {
      paths.push(`DIS${selectedDisease.code}`);
    } else if (selectedDepartment.code) {
      paths.push(`DEP${selectedDepartment.code}`);
    } else if (this.props.featureHospitalSearch?.campaign_code) {
      paths.push(`CAM${this.props.featureHospitalSearch.campaign_code}`);
    }

    const url = '/' + paths.join('/');

    if (keyword && keyword.length > 0) {
      const query = new URLSearchParams({ keyword: keyword });
      return url + '?' + query.toString();
    } else {
      return url;
    }
  }
}

BaseSearchBox.propTypes = {
  mixerHost: PropTypes.string.isRequired,
  prefectureData: PropTypes.array.isRequired,
  departmentSection: PropTypes.object.isRequired,
  carbonHost: PropTypes.string.isRequired,
  searchCondition: PropTypes.object,
  institutionType: PropTypes.string,
  prefectureCode: PropTypes.string,
  prefectureName: PropTypes.string,
  cityCode: PropTypes.string,
  cityName: PropTypes.string,
  stationCode: PropTypes.string,
  stationName: PropTypes.string,
  stationLineName: PropTypes.string,
  diseaseCode: PropTypes.string,
  diseaseName: PropTypes.string,
  departmentCode: PropTypes.string,
  departmentName: PropTypes.string,
  keyword: PropTypes.string,
  position: PropTypes.string,
  diseaseList: PropTypes.array,
  featureHospitalSearch: PropTypes.object
};

export default BaseSearchBox;
