import React from 'react';
import PropTypes from 'prop-types';

const CampaignCodeSearchCheckbox = (props) => {
  const { name, code } = props;
  const idName = `campaign_code_search_filter_code_${code}`;

  return (
    <dev className="p-hospitals-search__search-box-input-checkbox-wrap">
      <input className="p-hospitals-search__search-box-input-checkbox__check" id={idName} type="checkbox" value={code} />
      <label className="p-hospitals-search__search-box-input-checkbox__label" htmlFor={idName}>{name}</label>
    </dev>
  );
};

const CampaignCodeSearchFilterCheckboxField = (props) => {
  const { campaignCodeSearchFilters } = props;

  return (
    <div className="p-hospitals-search__search-box-input-text-wrap">
      <label className="c-form-label-responsive">絞り込み条件</label>
      <div className="p-hospitals-search__search-box-input-checkbox-inner">
        {campaignCodeSearchFilters.map(campaignCodeSearchFilter => <CampaignCodeSearchCheckbox key={campaignCodeSearchFilter.id} name={campaignCodeSearchFilter.name} code={campaignCodeSearchFilter.code} />)}
      </div>
    </div>
  );
};

CampaignCodeSearchFilterCheckboxField.propTypes = {
  campaignCodeSearchFilters: PropTypes.array.isRequired
};

CampaignCodeSearchCheckbox.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  code: PropTypes.string
};

export default CampaignCodeSearchFilterCheckboxField;
